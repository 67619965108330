.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 10rem;
  height: 3rem;
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}


.header-menu>li:hover {
  color: var(--orange);
  cursor: pointer; 
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}

.hero-buttons>:nth-child(1) {
  color: white;
  background-color: var(--orange);
  width: 8rem;
}

.hero-buttons>:nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--orange);
}

@media screen and (max-width: 768px) {
  .header>:nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
  }
}